import {format} from 'date-fns'
import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, getBlogUrl, getBlogCategoryUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

import '../styles/blog-post-archive.css'

function BlogPostArchive (props) {

  const {mainImage, title, publishedAt, slug, _rawExcerpt} = props

  return (

        <div className="blog-archive-item grid">

        <div className="width-12-12-m body-holder">

              <h2><Link to={getBlogUrl(publishedAt, slug.current)}>{title}</Link></h2>

              <div className="date-tag-holder">
                <div class="date-holder">
                  <span className="date">{format(publishedAt, 'MMMM Do, YYYY')}</span>
                </div>
                {props.categories && (
                  <div className="tags">
                      {props.categories.map(category => (
                        <Link className="tag" to={getBlogCategoryUrl(category.slug.current)}>{category.title}</Link>
                      ))}
                  </div>
                )}
              </div>

              {mainImage && mainImage.desktopImage && mainImage.desktopImage.asset && (
                <div className="mainImage-holder">
                  <Link to={getBlogUrl(publishedAt, slug.current)}>
                  <picture>
                    <source 
                      media="(max-width: 699px)" 
                      srcset={imageUrlFor(buildImageObj(mainImage.mobileImage))
                        .width(1000)
                        .height(Math.floor((1 / 1) * 1000))
                        .auto('format')
                        .url()}
                    />
                    <source 
                      media="(min-width: 700px)" 
                      srcset={imageUrlFor(buildImageObj(mainImage.desktopImage))
                        .width(2000)
                        .height(Math.floor((1 / 2) * 2000))
                        .auto('format')
                        .url()}
                    />
                    <img
                      src={imageUrlFor(buildImageObj(mainImage.desktopImage))
                        .width(2000)
                        .height(Math.floor((1 / 2) * 2000))
                        .auto('format')
                        .url()}
                      alt={mainImage.desktopImage.alt}
                      title={mainImage.desktopImage.alt}
                    />
                  </picture>
                  </Link>
                  {mainImage.desktopImage.caption && (
                    <span className="caption">
                      {mainImage.desktopImage.caption}
                    </span>
                  )}

                </div>
              )}

              {_rawExcerpt && (
                  <>
                  {_rawExcerpt && <PortableText blocks={_rawExcerpt} />}
                  </>
              )}

            </div>

        </div>

  )
}

export default BlogPostArchive