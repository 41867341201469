import React from 'react'
import {Link} from 'gatsby'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  filterOutCatsWithoutParents
} from '../lib/helpers'
import BlogPostArchiveList from '../components/blog-post-archive-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import '../styles/pagination.css'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
          metadata {
            dimensions {
              aspectRatio
            }
            lqip
          }
          _id
        }
  }
  query BlogQuery($skip: Int!, $limit: Int!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }
    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
          }
        }
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          publishedAt
          categories {
            _id
            title
            slug {
              current
            }
          }
          mainImage {
            desktopImage {
              ...SanityImage
              alt
              caption
            }
            mobileImage {
              ...SanityImage
              alt
              caption
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }

  }
`

const Blog = props => {
  const {data, errors} = props

  //console.log('omg')
  //console.log(props)

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []
  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []

  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()


  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink} >
      <SEO
        title={site.title}
        author={site.author}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>
        

        {postNodes && (
          <BlogPostArchiveList
            title='Blog'
            nodes={postNodes}
            browseMoreHref='/blog/'
          />
        )}

        <div className="pagination">
          <ul>
            {!isFirst && (
              <li><Link to={`/blog/${prevPage}`} rel="prev">
                ← Previous Page
              </Link></li>
            )}
            {numPages > 1 && Array.from({ length: numPages }, (_, i) => (
              <li
                key={`pagination-number${i + 1}`}
              >
                <Link
                  to={`/blog/${i === 0 ? '' : i + 1}`}
                  style={{
                    color: i + 1 === currentPage ? '#ffffff' : '',
                    background: i + 1 === currentPage ? '#000' : '',
                  }}
                >
                  {i + 1}
                </Link>
              </li>
            ))}
            {!isLast && (
              <li><Link to={`/blog/${nextPage}`} rel="next">
                Next Page →
              </Link></li>
            )}
          </ul>
        </div>

      </Container>
    </Layout>
  )
}

export default Blog