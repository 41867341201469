import React from 'react'
import BlogPostArchive from './blog-post-archive'

import '../styles/blog-post-archive-list.css'

function BlogPostArchiveList (props) {
  return (
    <>
    <div className="blog-post-archive-list wrapper">
      {props.title && <h3>{props.title}</h3>}
      <div className="blog-post-archive-holder">
          {props.nodes &&
            props.nodes.map(node => (
                <BlogPostArchive {...node} />
            ))}
      </div>
    </div>
    </>
  )
}

BlogPostArchiveList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BlogPostArchiveList